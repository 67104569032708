import { connect } from "react-redux";
import React, { Component } from "react";
import Sidebar from "react-sidebar";
import moment from "moment";
import Swal from "sweetalert2";

import { Images } from "../../theme";
import { DotsLoader, Header } from "../../components";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import {
  getResource,
  updateResource,
  deleteResource,
  createResource,
} from "../../config/simpleApiCalls";
import {
  get_employees,
  update_employee,
  delete_employee_Api,
  impersonation,
} from "../../config/WebServices";
import "./styles.css";
import Sytles from "./styles";
import {
  SidebarContent,
  Footer,
  CustomTable,
  TableContainerHeader,
  Popup,
} from "../../components/SuperAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          Header: "Name",
          accessor: "userId.firstName",
        },

        {
          Header: "Working Days / Hours",
          accessor: "userId.platform",
        },
        {
          Header: "Phone",
          accessor: "userId.phoneNo",
        },
        {
          Header: "Email",
          accessor: "userId.email",
        },
        {
          Header: "Status",
          Cell: (row) => this.renderTableEditDeleteIcon(row.row.original),
          accessor: "",
        },
      ],
      employeeData: [],
      isloading: true,
    };
  }

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => {
      this.handleGetEmployees();
    });
  }

  handleGetEmployees = (el) => {
    const { superAdminData } = this.state;
    getResource(get_employees, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            employeeData: res.data.data,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  handleDeleteEmployee = (_id) => {
    const { superAdminData } = this.state;

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#354e68",
      cancelButtonColor: "#ff3600",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ isloading: true });
        deleteResource(delete_employee_Api(_id), superAdminData?.access_token)
          .then((res) => {
            console.log("res: ", res);
            if (res.data.success) {
              SuccessHelper.handleSuccess(
                `Employee successfully delete.`,
                true
              );
              this.handleGetEmployees();
            } else {
              this.setState({ isloading: false });
            }
          })
          .catch((error) => {
            this.setState({ isloading: false });
            console.log(error);
          });
      }
    });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  intializeImpersonation = (userId) => {
    const { superAdminData } = this.state;
    this.setState({ isloading: true });
    createResource(impersonation, { userId }, superAdminData?.access_token)
      .then(({ data }) => {
        console.log(data);
        this.setState({ isloading: false });
        if (data.success) {
          const ewindow = window.open("https://easy1booking.com", "_blank");
          setTimeout(() => {
            ewindow.postMessage(
              JSON.stringify({ id: data.impersonation._id, type: "employee" }),
              "*"
            );
          }, 10000);
        }
      })
      .catch(() => {
        this.setState({ isloading: false });
      });
  };

  renderTableEditDeleteIcon = (data) => {
    return (
      <div>
        <span
          style={{
            marginRight: 8,
            backgroundColor: "green",
            padding: 4,
            borderRadius: 700,
          }}
        >
          <FontAwesomeIcon
            onClick={() => this.intializeImpersonation(data.userId._id)}
            style={{ width: 16, height: 16, cursor: "pointer" }}
            color="white"
            icon={["fas", "user-cog"]}
          />
        </span>
        <img
          onClick={() => {
            this.setState({
              firstName: data.userId.firstName,
              lastName: data.userId.lastName,
              dateOfBirth: data.userId.dob,
              password: data.userId.password,
              city: data.userId.city,
              province: data.userId.province,
              address: data.userId.address,
              postalCode: data.userId.postalCode,
              phoneNo: data.userId.phoneNo,
              email: data.userId.email,
              employeId: data._id,
            });
          }}
          style={{ height: 18, width: 18, marginRight: 8, cursor: "pointer" }}
          src={Images.table_edit}
          data-target="#exampleModal"
          data-toggle="modal"
        />
        <img
          onClick={() => {
            this.handleDeleteEmployee(data._id);
            // console.log(data._id, "delete");
          }}
          style={{ height: 18, width: 18, marginLeft: 8, cursor: "pointer" }}
          src={Images.table_delete}
        />
      </div>
    );
  };

  handleUpdateEmployee = () => {
    // alert("update");
    let {
      firstName,
      lastName,
      dateOfBirth,
      city,
      province,
      postalCode,
      phoneNo,
      superAdminData,
      address,
      employeId,
    } = this.state;

    console.log(
      "?????",
      firstName,
      lastName,
      // dateOfBirth,
      city,
      province,
      postalCode,
      phoneNo,
      address
    );

    let payload = {
      firstName,
      lastName,
      address,
      postalCode,
      city,
      province,
      employeeId: employeId,
    };

    this.setState({ isloading: true });

    updateResource(update_employee, payload, superAdminData?.access_token)
      .then((res) => {
        console.log("update_employee: ", res);
        if (res.data.success) {
          this.setState({
            isloading: false,
            data: res.data.data,
          });
          SuccessHelper.handleSuccess(`Employee successfully updated.`, true);
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });

        ErrorHelper.handleErrors("Something Went Wrong", true);
        console.log(error);
      });
  };

  renderData = () => {
    let {
      firstName,
      lastName,
      dateOfBirth,
      password,
      city,
      province,
      address,
      postalCode,
      phoneNo,
      email,
    } = this.state;

    return (
      <div class="container-fluid">
        <div class="row">
          <div class="form-group col-md-4">
            <label for="fName">First Name:</label>
            <input
              type="text"
              value={firstName}
              onChange={(firstName) =>
                this.setState({ firstName: firstName.target.value })
              }
              class="form-control"
              id="fName"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="lName">Last Name:</label>
            <input
              type="text"
              onChange={(lastName) =>
                this.setState({ lastName: lastName.target.value })
              }
              value={lastName}
              class="form-control"
              id="lName"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="birth">Date Of Birth:</label>
            <input
              type="date"
              value={moment(dateOfBirth).format("YYYY-MM-DD")}
              onChange={(dateOfBirth) =>
                this.setState({ dateOfBirth: dateOfBirth.target.value })
              }
              class="form-control"
              id="birth"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        <div class="row">
          {/* <div class="form-group col-md-4">
            <label for="password">Password:</label>
            <input
              type="password"
              value={password}
              class="form-control"
              id="password"
              aria-describedby="emailHelp"
            />
          </div> */}
          <div class="form-group col-md-4">
            <label for="city">City:</label>
            <input
              type="text"
              value={city}
              onChange={(city) => this.setState({ city: city.target.value })}
              class="form-control"
              id="city"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="province">Province:</label>
            <input
              type="text"
              value={province}
              onChange={(province) =>
                this.setState({ province: province.target.value })
              }
              class="form-control"
              id="province"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="postalCode">Postal Code:</label>
            <input
              type="text"
              value={postalCode}
              onChange={(postalCode) =>
                this.setState({ postalCode: postalCode.target.value })
              }
              class="form-control"
              id="postalCode"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
        {/* Company Short Description: */}

        {/* <div class="row">
          <div class="form-group col-md-12">
            <label for="address">Address:</label>
            <input
              type="text"
              value={address}
              class="form-control"
              id="address"
              aria-describedby="emailHelp"
            />
          </div>
        </div> */}

        <div class="row">
          {/* <div class="form-group col-md-4">
            <label for="postalCode">Postal Code:</label>
            <input
              type="text"
              value={postalCode}
              class="form-control"
              id="postalCode"
              aria-describedby="emailHelp"
            />
          </div> */}
          <div class="form-group col-md-4">
            <label for="phoneNo">Telephone:</label>
            <input
              type="number"
              class="form-control"
              value={phoneNo}
              onChange={(phoneNo) =>
                this.setState({ phoneNo: phoneNo.target.value })
              }
              id="phoneNo"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-8">
            <label for="address">Address:</label>
            <input
              type="text"
              value={address}
              onChange={(address) =>
                this.setState({ address: address.target.value })
              }
              class="form-control"
              id="address"
              aria-describedby="emailHelp"
            />
          </div>

          {/* <div class="form-group col-md-4">
            <label for="exampleInputEmail1">Email:</label>
            <input
              type="email"
              disabled="true"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div> */}
        </div>
      </div>
    );
  };

  render() {
    const { columns, employeeData: all, weekplans, searchText } = this.state;
    const employeeData = all.filter((val) => {
      if (!val?.userId?.firstName) return false;
      return val.userId.firstName
        .toLocaleLowerCase()
        .includes(searchText || "");
    });
    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
          <div className="container py-5 mb-4 mb-md-5">
            <div className="row">
              <div className="col-12 mt-4 bg-white shadow rounded px-0">
                <TableContainerHeader
                  headingText="Employees"
                  onSearchVal={(searchText) => this.setState({ searchText })}
                  buttonOnClick={() => {}}
                />
                <CustomTable columns={columns} data={employeeData} />
                <Popup
                  exampleModal="exampleModal"
                  heading="Edit Employee"
                  cancel="Cancel"
                  confirmBtn={() => this.handleUpdateEmployee()}
                  confirm="Update"
                  data={this.renderData()}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Employees);
