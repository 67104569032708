import { fork } from "redux-saga/effects";

import login from "./Login";
import forgotPassword from "./ForgotPassword";
import verifyResetCode from "./VerifyResetCode";
import resetPassword from "./ResetPassword";
import logout from "./LogOut";
import announcements from "./Announcements";

export default function* rootSaga() {
  yield fork(login);
  yield fork(forgotPassword);
  yield fork(verifyResetCode);
  yield fork(resetPassword);
  yield fork(logout);
  yield fork(announcements);
}
