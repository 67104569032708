import { connect } from "react-redux";
import React, { Component } from "react";
import Sidebar from "react-sidebar";

import { Images } from "../../theme";
import { DotsLoader, Header } from "../../components";
import { ErrorHelper, SuccessHelper } from "../../helpers";
import {
  getResource,
  deleteResource,
  updateResource,
  createResource,
} from "../../config/simpleApiCalls";
import {
  get_customers,
  delete_customer_Api,
  update_customer,
  impersonation,
} from "../../config/WebServices";

import Swal from "sweetalert2";

import "./styles.css";
import Sytles from "./styles";
import {
  SidebarContent,
  Footer,
  CustomTable,
  TableContainerHeader,
  Popup,
} from "../../components/SuperAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          Header: "Name",
          accessor: "userName",
        },
        {
          Header: "Email",
          accessor: "email",
        },
        {
          Header: "Phone",
          accessor: "phoneNo",
        },
        {
          Header: "Postal Code",
          accessor: "postalCode",
        },
        {
          Header: "Type",
          accessor: "type",
        },
        {
          Header: "Status",
          Cell: (row) => this.renderTableEditDeleteIcon(row.row.original),
          accessor: "status",
        },
      ],
      isloading: true,
      data: [],
    };
  }

  componentDidMount() {
    const superAdminData = JSON.parse(sessionStorage.getItem("superAdmin"));
    this.setState({ superAdminData }, () => {
      this.handleGetCustomer();
    });
  }

  handleGetCustomer = (el) => {
    const { superAdminData } = this.state;
    getResource(get_customers, superAdminData?.access_token)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            isloading: false,
            data: res.data.data,
          });
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        console.log(error);
      });
  };

  handleDeleteCustomer = (_id) => {
    console.log("_id: ", _id);
    const { superAdminData } = this.state;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#354e68",
      cancelButtonColor: "#ff3600",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ isloading: true });
        deleteResource(delete_customer_Api(_id), superAdminData?.access_token)
          .then((res) => {
            console.log("res: ", res);
            if (res.data.success) {
              SuccessHelper.handleSuccess(
                `Customer successfully delete.`,
                true
              );
              this.handleGetCustomer();
            } else {
              this.setState({ isloading: false });
            }
          })
          .catch((error) => {
            this.setState({ isloading: false });
            console.log(error);
          });
      }
    });
  };

  handleUpdateCustomer = () => {
    let { userName, postalCode, userId, superAdminData } = this.state;

    let payload = {
      userName,
      postalCode,
      userId,
    };

    this.setState({ isloading: true });

    updateResource(update_customer, payload, superAdminData?.access_token)
      .then((res) => {
        console.log("res: ", res);
        if (res.data.success) {
          this.setState({
            isloading: false,
          });
          this.handleGetCustomer();
          SuccessHelper.handleSuccess(`Customer successfully updated.`, true);
        } else {
          this.setState({ isloading: false });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });

        ErrorHelper.handleErrors("Something Went Wrong", true);
        console.log(error);
      });
  };

  handleNavigation = (route) => {
    this.props.history.push(route);
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  };

  renderLoader = () => {
    const { isloading } = this.state;
    return <DotsLoader isloading={isloading} />;
  };

  intializeImpersonation = (userId) => {
    const { superAdminData } = this.state;
    this.setState({ isloading: true });
    createResource(impersonation, { userId }, superAdminData?.access_token)
      .then(({ data }) => {
        this.setState({ isloading: false });
        if (data.success) {
          const ewindow = window.open("https://easy1booking.com", "_blank");
          setTimeout(() => {
            ewindow.postMessage(
              JSON.stringify({ id: data.impersonation._id, type: "user" }),
              "*"
            );
          }, 10000);
        }
      })
      .catch(() => {
        this.setState({ isloading: false });
      });
  };

  renderTableEditDeleteIcon = (userInfo) => {
    return (
      <div>
        <span
          style={{
            marginRight: 8,
            backgroundColor: "green",
            padding: 4,
            borderRadius: 700,
          }}
        >
          <FontAwesomeIcon
            onClick={() => this.intializeImpersonation(userInfo._id)}
            style={{ width: 16, height: 16, cursor: "pointer" }}
            color="white"
            icon={["fas", "user-cog"]}
          />
        </span>
        <img
          onClick={() => {
            this.setState({
              userName: userInfo.userName
                ? userInfo.userName
                : userInfo.firstName,
              postalCode: userInfo.postalCode,
              userId: userInfo._id,
            });
          }}
          style={{ height: 18, width: 18, marginRight: 8, cursor: "pointer" }}
          src={Images.table_edit}
          data-target="#exampleModal"
          data-toggle="modal"
        />
        <img
          onClick={() => this.handleDeleteCustomer(userInfo._id)}
          style={{ height: 18, width: 18, marginLeft: 8, cursor: "pointer" }}
          src={Images.table_delete}
        />
      </div>
    );
  };

  renderData = () => {
    const { postalCode, userName } = this.state;

    return (
      <div class="container-fluid">
        <div class="row">
          <div class="form-group col-md-4">
            <label
              for="name"
              style={{ color: "lightgray", fontWeight: "bold" }}
            >
              Name:
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              onChange={(e) => this.setState({ userName: e.target.value })}
              value={userName}
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group col-md-4">
            <label
              for="phoneCode"
              style={{ color: "lightgray", fontWeight: "bold" }}
            >
              Postal Code:
            </label>
            <input
              type="number"
              onChange={(e) => this.setState({ postalCode: e.target.value })}
              value={postalCode}
              class="form-control"
              id="phoneCode"
              aria-describedby="emailHelp"
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { columns, data: all, searchText } = this.state;
    const data = all.filter((val) => {
      if (!val?.userName) return false;
      return val.userName.toLocaleLowerCase().includes(searchText || "");
    });
    return (
      <Sidebar
        sidebar={<SidebarContent {...this.props} />}
        open={this.state.sidebarOpen}
        onSetOpen={(val) => this.onSetSidebarOpen(val)}
        sidebarClassName="custom-sidebar"
        styles={Sytles}
      >
        {this.renderLoader()}
        <div
          className="container-fluid min-vh-100 px-0"
          style={{ backgroundColor: "#F7F7F7" }}
        >
          {<Header {...this.props} onSetSidebarOpen={this.onSetSidebarOpen} />}
          <div className="container py-5 mb-4 mb-md-5">
            <div className="row">
              <div className="col-12 mt-4 bg-white shadow rounded px-0">
                <TableContainerHeader
                  onSearchVal={(searchText) => this.setState({ searchText })}
                  headingText="Customer"
                  buttonOnClick={() => {}}
                />
                <CustomTable columns={columns} data={data} />
                <Popup
                  exampleModal="exampleModal"
                  heading="Edit Customer"
                  data={this.renderData()}
                  cancel="Cancel"
                  confirm="Update"
                  confirmBtn={() => this.handleUpdateCustomer()}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({});

const action = {};

export default connect(mapStateToProps, action)(Customer);
