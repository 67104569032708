import React, { Component } from "react";
import MyRoutes from "./routes";
import { Provider } from "react-redux";

import Store from "./redux/store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

const store = Store();

export default class App extends Component {
  //firestore query
  // jis jis user k collection k andr adminToken ki key hogi os user ko
  //notification bhej dena order place hote wqt

  // sentNoti = ()=>{
  //   //Server Key (Firebase -> Project -> Settings -> Cloud Messaging -> Server Key
  //   var key ='AAAAp_HXyk8:APA91bHOdwhK1gAbpQk2IjPvKBGjvb694ZMsojy4F7lxFuk8dWwv5uOwehgM768PceFvQUbSWYfWr77DQPVXeBICAGWgLy1zBMtw2wR9W448K77Chxz2tPX8sgBt3xETqbcreyNiUi0-';
  //   //token
  //   var to = "eyIB1PF-ayQ2QtJytgeBN1:APA91bEmuiikVuLPXIQM9JNq7L-WJqNpC7FxO1CUpvxcrvoHOrPijQfrz3dSDEXFFxULwqW3lnyTip3QLE-teA1Z-RZmd1JpwiZyHGmQsOXOkcxRsbX4_dOgrjvyXTSf-JXyYypiRslU";
  //   var notification = {
  //     'title': 'Test Notification',
  //       'body': 'Test Notification',
  //       'icon':'https://images-na.ssl-images-amazon.com/images/I/81Qv1gBLzYL.png'
  //   };
  //   fetch('https://fcm.googleapis.com/fcm/send', {
  //     'method': 'POST',
  //     'headers': {
  //       'Authorization': 'key=' + key,
  //       'Content-Type': 'application/json'
  //     },
  //     'body': JSON.stringify({
  //       'notification': notification,
  //       'to': to
  //     })
  //   }).then(function(response) {
  //     console.log("sss",response);
  //   }).catch(function(error) {
  //     console.error(error);
  //   });
  // }

  // async componentDidMount() {
  //   messaging.requestPermission()
  //     .then(async function () {
  //       const token = await messaging.getToken();
  //       sessionStorage.setItem('messaging_token', token);
  //     })
  //     .catch(function (err) {
  //       console.log("Unable to get permission to notify.", err);
  //     });
  //   navigator.serviceWorker.addEventListener("message",
  //     (message) => {
  //       SuccessHelper.handleToastSuccess(`${message.data['firebase-messaging-msg-data'].notification.body}`, true);
  //       // console.log("message", message)
  //       // console.log("notification data", JSON.parse(message.data['firebase-messaging-msg-data'].data.body))
  //     });
  // }

  render() {
    return (
      <Provider store={store}>
        <MyRoutes />
      </Provider>
    );
  }
}
