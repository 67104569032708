import axios from "axios";
import { baseUrl } from "./config/WebServices";

const instance = axios.create({
  baseURL: baseUrl,
});

instance.interceptors.request.use((config) => {
  config.headers.Authorization = sessionStorage.getItem("accessToken");
  return config;
});

instance.interceptors.response.use((response) => {
  if (
    !(
      response.ok &&
      response.data &&
      response.originalError === null &&
      response.status === 200 &&
      response.data.success
    )
  ) {
    if (!response.data.success) {
      throw new Error(response.data.msg);
    } else if (
      response.problem === "NETWORK_ERROR" &&
      !response.ok &&
      response.originalError
    ) {
      throw new Error(response.problem);
    }
  }
  return response.data;
});

export default instance;
